import React from 'react';

import reason1 from '../images/business/reason-1.svg';
import reason2 from '../images/business/reason-2.svg';
import reason3 from '../images/business/reason-3.svg';
import reason4 from '../images/business/reason-4.svg';
import step1 from '../images/business/step-1.svg';
import step2 from '../images/business/step-2.svg';
import step3 from '../images/business/step-3.svg';
import crossLogo from '../images/business/logos/image-18.png';
import barLogo from '../images/business/logos/image-19.png';
import acmLogo from '../images/business/logos/image-20.png';
import compassLogo from '../images/business/logos/image-21.png';
import monbatLogo from '../images/business/logos/image-22.png';


export default {
  reasons: [
    {
      // title: 'Непрекъсваем, сигурен и бърз интернет',
      // content: 'Край на прекъсванията на онлайн бизнес/конферентната среща или оперативката, на липсата на достъп до фирмения сървър и до служебната поща, на спирането в най-напечения момент на свалянето на важни файлове. С NetSurf си независим от метеорологичните условия или електрозахранването заради оптичната GPON технология в подземна канална мрежа, и то при денонощна връзка с нас. Повече от бърз оптичен интернет със скорост до 300 Mbit/s.',
      color: '#2E6D98',
      image: reason1,
      bgColor: '#2F5685',
    },
    {
      // title: 'Уникален клиент – уникална оферта и отношение',
      // content: 'От старта си като компания съзнаваме, че ти имаш свои нужди, желания и виждания за нашия продукт. Като корпоративен клиент, от NetSurf получаваш индивидуално отношение и професионално обслужване от конкретен наш представител. Богатото ни и разнообразно портфолио от телекомуникационни услуги, съобразени с конкретните потребности на бизнеса ти, ни отличава с възможността да ти отправим индивидуална оферта. Имаме и денонощен VIP Support център, като въпросите на корпоративния потребител разглеждаме с приоритет и реагираме на мига.',
      color: '#2E6D98',
      image: reason2,
      bgColor: '#2F5685',
    },
    {
      // title: 'За вътрешните хора – вътрешни мрежи',
      // content: 'Не всеки го може и прави, но ние сме доказано добри в това да изграждаме вътрешни кабелни мрежи в обектите на нашите корпоративни клиенти и им предоставяме необходимото оборудване за използване на телекомуникационни услуги. NetSurf също така изготвя конвергирани телекомуникационни решения, чрез които всеки корпоративен клиент може да обедини всички използвани на различни локации наши услуги.',
      color: '#2E6D98',
      image: reason3,
      bgColor: '#2F5685',
    },
    {
      // title: 'Бизнес лидерите работят с бизнес лидери',
      // content: 'NetSurf е българска компания с над 20-годишен опит в бранша и утвърдено име, което е символ за висококачествени и надеждни телекомуникационни услуги, лидер в бизнеса с доставянето на интернет и телевизия за офиса. Лидер, който неспирно расте ръка за ръка с над 18 000 щастливи клиенти.',
      color: '#2E6D98',
      image: reason4,
      bgColor: '#2F5685',
    },
  ],

  clientOpinions: [
    {
      // name: '',
      opinion: '"За нас един клиент освен, да получава перфектно обслужване, трябва и да се чувства уютно в нашия бар-ресторант. Част от този комфорт и уют е и високоростната интернет връзка предоставяна от NutSurf, чрез която клиентите ни могат да споделят щастливите си моменти. А ТВ услугата пък, допълва приятния престой на клиенти ни в нашия ресторант."',
      date: 'Бар "Шери", Благоевград',
      logo: barLogo,
    },
    {
      // name: 'Иван Иванов',
      opinion: '"Преди да започне партньорството ни с NetSurf, често страдахме от некачествена интернет връзка. Този проблем влияееше негативно както на вътрешнофирмените комуникационни процес, така и на отношенията с партньорите ни. Имахме нужда не само от качествена услуга, но и от адекватен съпорт при възникване на проблем. През 6 годишното ни партньорство с NetSurf сме обслужвани от екип професионалист, с особено внимание към изискванията, нуждите ни и своевременна реакция. Нашето доверие за качество на услугите и обслужване в сферата на телекомуникационните услуги е в ръцете на NetSurf, компания с над 20 годишен опит в IT сферата."',
      date: "Крос, Монтана",
      logo: crossLogo,
    },
    {
      opinion: '"Като лидер в дистрибуцията на решения в сферата на заемеделието в България, за нас е изключително важно да поддържаме високо ниво на онлайн комуникация, с партньори, клиенти и с всичките ни обекти в страната. Запазването на лидерските ни позиции е постижимо само с помощта на висококачествени дигитални иновации, които отговарят на нашето темпо. За базите ни в северозападна България избрахме NetSurf, тъй като са лидери в комуникационните услуги, с изградено име като надежден бизнес партньор.Стремежът им към покоряване на нови върхове, води до постоянна еволюция на услугите им, която ни осигурява спокойствие, че получаваме качествени и актуални решения за бизнеса си."',
      date: 'АСМ, Монтана',
      logo: acmLogo,
    },
    {
      // name: 'Иван Иванов',
      opinion: '"Мисията на нашата фирма е да предлага висококачествени хранителни продукти, които подобряват качествто на живот на хората. Като водеща компания в бранша на производството на стеризлизирани продукти, за нас е изключително важно да поддържаме най-високо ниво на онлайн комуникация с нашите партньори и клиенти. Това е причината, при избора на интернет доставчик, да се доверим на NetSurf. В тяхно лице открихме висококвалифициран и надежден бизнес партньор, а това ни дава спокойствието да гледаме на професионалните ни взаимоотношения в дългосрочен план."',
      date: 'Компас, Монтана',
      logo: compassLogo,
    },
    {
      // name: 'Иван Иванов',
      opinion: '"NetSurf е наш основен партньор за предоставянето на интернет услуги.  Компанията е с повече от 20-години опит и лидер на пазара на телекомуникациите в Северозападна и Югозападна България. Стартирахме сътрудничество преди повече от 6 години.В момента разчитаме на тях и в производствената, и в административната база в Монтана, тъй като ежедневието в Монбат е свързано с постоянна комуникация на вътрешнокоропративно и международно ниво. Услугите, които получаваме от NetSurf, са доставка на високоскоростен оптичен интернет, изграждане на вътрешни локални и оптични мрежи и тяхната техническа поддръжка.Безупречни са!"',
      date: 'Захари Трифонов, IT Мениджър\nМонбат" АД, Монтана',
      logo: monbatLogo,
    }
  ],
  verticalSteps: [
    {
      title: 'Да се запознаем',
      text: 'Всяко добро сътрудничество се гради на взаимното опознаване. Разкажи ни за твоя бизнес и неговите конкретни нужди, след което ще обсъдим вариантите за оптималния пакет за теб. Само се свържи с нас по телефона, изпрати запитване в уебсайта ни или попитай на място в някой от нашите магазини.',
      img: step1,
    },
    {
      title: 'Изготвяне на лично твоята оферта',
      text: 'След като вече познаваме твоите специфични нужди и желания, нашият екип от професионалисти ще помогне за намирането на точния индивидуален пакет за теб. Ние в NetSurf винаги се приспособяваме към твоя бизнес, не ти към нашия.',
      img: step2,
    },
    {
      title: 'Светкавично бързо включване',
      text: 'В рамките на само три работни дни след подписване на договора вече ще ползваш свръхкачествените ни интернет и телевизионни услуги, и то без никакви такси за оборудване и с безплатно включване*. И ние работим като теб – по всяко време, здраво и ефикасно. Да запретваме ръкави!',
      text2: '* Безплатното включване важи само за места, на които имаме 100% покритие (и/или не се налага да предоставяме услугата чрез комуникационна антена) и с разход на до 50 м кабел.',
      img: step3,
    },
  ],
  faqs: [
    {
      title: 'Какви са ползите за мен да използвам Бизнес услуги?',
      content: (
        <>
          <p className='text-white'>Всеки наш Бизнес клиент се възползва от следните предимства:</p>
          <ul className='green-ticks'>
            <li><p className='text-white'>индивидуално и отговорно отношение от конкретен наш представител</p></li>
            <li><p className='text-white'>изготвяне на индивидуално предложение за бизнес услуги с параметри и качество, съобразени с нуждите на бизнеса Ви</p></li>
            <li><p className='text-white'>получаване на индивидуални отстъпки при включване на множество обекти и обща фактура, обединяваща всичките Ви бизнес услуги</p></li>
            <li><p className='text-white'>бърза реакция от денонощен Съпорт център и разглеждане на възникналите въпроси с приоритет</p></li>
          </ul>
        </>)
    },
    {
      title: 'Какви скорости на бизнес интернет предлагате?',
      content: <p className='text-white'>NetSurf може да предостави на Бизнес клиент скорост до 1Gbps по GPON технология в зависимост от желаната локация.</p>,
    },
    {
      title: 'Кои услуги са най-подходящи за бизнеса ми?',
      content: <p className='text-white'>Ние в NetSurf стоим близо до потребностите на нашите Бизнес клиенти и изготвяме конкретни предложения, съобразени с изискванията на бизнеса им. Затова свържете се с нас, за да установим заедно най-подходящите за Вас услуги.</p>,
    },
    {
      title: 'Какви допълнителни услуги може да ми предложите?',
      content: (
        <>
          <p className='text-white'>NetSurf може да предостави следните допълнителни услуги:</p>
          <ul className='green-ticks'>
            <li><p className='text-white'>изграждане на вътрешна кабелна мрежа</p></li>
            <li><p className='text-white'>изграждане на безжична мрежа</p></li>
            <li><p className='text-white'>поддръжка на вътрешни мрежи</p></li>
            <li><p className='text-white'>хардуерна поддръжка</p></li>
            <li><p className='text-white'>софтуерна поддръжка</p></li>
            <li><p className='text-white'>денонощен мониторинг на предоставяните бизнес услуги</p></li>
            <li><p className='text-white'>продажба на устройства  - рутери, телевизори, таблети, лаптопи и др.</p></li>
          </ul>
        </>)
    },
    {
      title: 'Какъв е срокът за предоставяне на бизнес услуги?',
      content: <p className='text-white'>Нормалният срок за предоставяне на избраните от Вас бизнес услуги е до 3 работни дни. Въпреки това, в NetSurf поставяме потребностите на всеки Бизнес клиент на първо място и можем при необходимост да реагираме веднага, предоставяйки желаните услуги възможно най-бързо.</p>,
    },
    {
      title: 'Кога и как ще получавам фактурата си?',
      content: <p className='text-white'>Месечната фактура се генерира и изпраща автоматично на всяко първо число от месеца на посочен от Вас имейл адрес.</p>,
    },
    {
      title: 'До кога трябва да заплатя издадената фактура?',
      content: <p className='text-white'>Издадената на първо число на всеки месец фактура подлежи на плащане до края на месеца, през който е издадена.</p>,
    },
    {
      title: 'Как мога да заплатя месечната си такса?',
      content: (
        <>
          <p className='text-white'>Можете да заплатите месечната си такса по един от следните начини:</p>
          <ul className='green-ticks'>
            <li><p className='text-white'>по банков път</p></li>
            <li><p className='text-white'>в магазин на NetSurf</p></li>
            <li><p className='text-white'>през мобилното приложение и в клиентския портал, посредством ePay</p></li>
            <li><p className='text-white'>на каса на EasyPay</p></li>
          </ul>
        </>)
    },
    {
      title: 'Има ли гратисен период, в който услугата няма да спре?',
      content: <p className='text-white'>Гратисният период е един месец, защото издаваната на първо число всеки месец фактура подлежи на плащане до края на месеца, през който е издадена.</p>,
    },
  ]
}
