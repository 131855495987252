import '../styles/business.css';

import React, { useState } from 'react';

import BottomCta from '../components/shared/bottom-cta';
import CallCTA from '../components/business/call-cta';
import ClientLogosSection from '../components/business/client-logos-section';
import CollapsibleSection from '../components/shared/collapsable';
import FormPopup from '../components/shared/form-popup';
import Hero from '../components/shared/hero-business';
import Layout from '../components/layout';
import Reasons from '../components/shared/reasons';
import SEO from '../components/seo';
import ScrollAnimation from 'react-animate-on-scroll';
import TestimonialSlider from '../components/shared/testimonial-slider';
import VerticalSteps from '../components/shared/vertical-steps';
import constants from '../constants/business';
import { graphql } from 'gatsby';
import leftImageSteps from '../images/business/vertical-steps.svg';
import meta from '../constants/meta-descriptions';
import styled from 'styled-components';

const BusinessPage = ({ data }) => {
	const [showForm, setShowForm] = useState(false);

	let steps = [];
	data.wordpressPage.acf.steps.split('\n').map((rString) => {
		let parts = rString.split('###');

		steps.push({
			title: parts[0],
			text: parts[1]
		});
	});

	return (
		<>
			<div className="business-page">
				<BackgroundWrapper bg={data.bgImage.childImageSharp.fluid.src}>
					<Layout activeTab={'/za-biznesa/'}>
						<SEO
							title={meta.business.title}
							description={meta.business.metaDescription}
						/>

						<Hero
							heroImage={data.heroImage.childImageSharp.fluid.src}
							imageClass="hero-business"
							title={
								<>
									<h1 style={{ whiteSpace: 'pre-line' }}>
										<span className="d-none d-sm-inline-block text-white mt-5 mx-1">
											Твоят бизнес си е твоя работа,
											<br />
											нашият е да го свържем
										</span>
										<span className="d-inline d-sm-none text-white mt-5 mx-2">
											Твоят бизнес си е<br />
											твоя работа,
											<br />
											нашият е да го свържем
										</span>
									</h1>
								</>
							}
						/>

						<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
							<ClientLogosSection logos={data.logos} />
						</ScrollAnimation>

						<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
							<Reasons
								sectionTitle={
									<h2
										className="text-white"
										style={{ whiteSpace: 'pre-wrap' }}
									>
										Четири причини офисът
										<br /> ти да е на вълната <br />
										на NetSurf
									</h2>
								}
								reasonsData={constants.reasons}
								reasonsWpData={data.wordpressPage.acf.reasons}
								textClassName={'text-white'}
								isDark={true}
							/>
						</ScrollAnimation>

						<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
							<CallCTA
								bgImg={data.ctaBg.childImageSharp.fluid.src}
								bgPerson={data.ctaPerson.childImageSharp.fluid}
							>
								<div className="text-md-left text-center">
									<h2 className="mb-md-5 mb-2 d-nones d-md-blocks text-white">
										Всичко е лично,
										<br /> особено в бизнеса
									</h2>
									<p className="mb-md-5 mb-3 text-white">
										Рамо до рамо ще изготвим индивидуален пакет за
										твоя бизнес.
									</p>
									<div className="d-inline-block">
										<a
											href={'tel:088 299 1596'}
											className="btn btn-primary green text-left d-inline-block mt-2 mr-md-2 mb-2"
											id="b2b-pcta-call"
										>
											Обади се на 088 299 1596
										</a>
									</div>
									<div className="d-inline-block">
										<button
											className="btn btn-primary dark-blue"
											id="b2b-pcta-formrequest"
											type="button"
											onClick={(_) => setShowForm(true)}
										>
											Изпрати запитване
										</button>
									</div>
								</div>
							</CallCTA>
						</ScrollAnimation>

						<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
							<VerticalSteps
								title={
									<h2 className="text-white">
										Трите крачки <br />
										към летящия ни <br />
										старт заедно
									</h2>
								}
								isDark={true}
								steps={steps}
								leftImage={leftImageSteps}
							/>
						</ScrollAnimation>

						<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
							<TestimonialSlider
								sectionTitle={
									<h2 className="text-white">
										Какво мислят
										<br />
										другите за нас
									</h2>
								}
								opinions={data.allWordpressWpTestimonials.edges}
								isWhiteArrows={true}
								isDark={true}
							/>
						</ScrollAnimation>

						<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
							<BottomCta bgImg={data.ctaBg.childImageSharp.fluid.src}>
								<h2 className="mb-4 d-none d-md-block text-white">
									Нямаме търпение да ти покажем
									<br /> колко сме добри!
								</h2>
								<h2 className="mb-4 d-block d-md-none text-white">
									Нямаме търпение да ти покажем колко сме добри!
								</h2>
								<p className="mb-4 text-white">
									Нашите предложения са ти точно по мярка
								</p>
								<button
									className="btn btn-primary green"
									onClick={(_) => setShowForm(true)}
									type="button"
									id="b2b-pcta-formrequest"
								>
									Нека го обсъдим
								</button>
							</BottomCta>
						</ScrollAnimation>

						<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
							<CollapsibleSection
								isDark={true}
								faqs={data.allWordpressWpFaqs.edges.map(
									({ node }) => node
								)}
								sectionTitle={
									<>
										<h2
											className="text-white"
											style={{ whiteSpace: 'pre-wrap' }}
										>
											Често задавани въпроси
										</h2>
										<hr className="center blue" />
									</>
								}
							/>
						</ScrollAnimation>
					</Layout>
				</BackgroundWrapper>
			</div>

			<FormPopup show={showForm} setShow={setShowForm} isBusiness={true} />
		</>
	);
};

const BackgroundWrapper = styled.div`
	background-image: url(${(props) => props.bg});
	background-position: top;
	background-size: cover;
	background-repeat: repeat-y;
`;

export const query = graphql`
	query BusinessPageQuery {
		heroImage: file(relativePath: { eq: "business/hero-header.png" }) {
			childImageSharp {
				fluid(maxHeight: 1852) {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
		bgImage: file(relativePath: { eq: "business/business-service-bg.png" }) {
			childImageSharp {
				fluid(maxHeight: 6056) {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
		ctaBg: file(relativePath: { eq: "business/cta-bg.png" }) {
			childImageSharp {
				fluid(maxHeight: 838) {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
		ctaPerson: file(relativePath: { eq: "business/call-cta-person.png" }) {
			childImageSharp {
				fluid(maxHeight: 1038) {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
		logos: allFile(filter: { sourceInstanceName: { eq: "logo-clients-images" } }) {
			edges {
				node {
					childImageSharp {
						fluid(maxWidth: 360) {
							...GatsbyImageSharpFluid_withWebp_noBase64
						}
					}
				}
			}
		}
		wordpressPage(slug: { eq: "za-biznesa" }) {
			acf {
				reasons
				steps
			}
		}
		allWordpressWpFaqs(filter: { acf: { category: { eq: "za-biznesa" } } }) {
			edges {
				node {
					title
					content
				}
			}
		}
		allWordpressWpTestimonials(filter: { acf: { category: { eq: "za-biznesa" } } }) {
			edges {
				node {
					acf {
						location
					}
					title
					content
					featured_image_url
				}
			}
		}
	}
`;

export default BusinessPage;

