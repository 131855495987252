import React from 'react';
import Img from 'gatsby-image'
import SectionTitle from '../shared/section-titles';

export default ({ logos }) => {
  return (
    <>
      <section id='client-logos' className='center spacing-bottom spacing-top'>
        <div className='row w-100'>
          <SectionTitle>
            <h2 className='text-white' style={{ whiteSpace: 'pre-wrap' }}>Само част от компаниите,<br /> с които растем заедно</h2>
            <hr className='center blue' />
          </SectionTitle>
          <div className='container'>
            <div className='row w-100 d-flex justify-content-center'>
              <div className='col-1 d-none d-lg-block'></div>
              {logos.edges.map(({ node }, index) => (
                <div key={index} className='col-6 col-sm-4 col-md-3 col-lg-2 d-flex justify-content-center align-items-center'>
                  <Img fluid={node.childImageSharp.fluid} className='m-3' style={{minWidth: 120}} alt='Лого на компания' />
                </div>
              ))}
              <div className='col-1 d-none d-lg-block'></div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}