import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

const Wrapper = styled.div`
  background-image: url(${props => props.bgImg});
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 8px;
  box-shadow: 0px 8.71336px 58.089px rgba(0, 0, 0, 0.15);
  max-height: 25rem;
  z-index: 2;

`;

const Container = styled.div`
  overflow-x: visible !important;
  position: relative;

  @media screen and (max-width: 991px) {
    overflow-x: hidden !important;  
  }
`;

const SmallImageContainer = styled.div`
  position: relative;
  z-index: 0;
`;

const CallCTA = ({ bgImg, bgPerson, children }) => {
  return (
    <>
      <section id='call-cta' className='center spacing-bottom'>
        <Container>
          <SmallImageContainer className='d-none' style={{ maxHeight: "350px" }}>
            <Img fluid={bgPerson} className='call-cta-person' style={{ maxHeight: "350px" }} imgStyle={{ objectFit: "contain" }}
              alt='Позвънете на NetSurf' />
          </SmallImageContainer>
          <div className='row my-5 justify-content-center align-items-center w-100'>
            <Wrapper className='container elevated text-center mx-2 my-5 py-5' bgImg={bgImg}>
              <div className='w-100'>
                <div className='row'>
                  <div className='col-12 col-xl-7 px-md-5'>
                    {children}
                  </div>
                  <div className='col-xl-4 d-none d-xl-block call-cta-person-wrapper'>
                    <Img fluid={bgPerson} className='call-cta-person' alt='Позвънете на NetSurf' />
                  </div>
                </div>
              </div>
            </Wrapper>
          </div>
        </Container>
      </section>
    </>
  );
}

export default CallCTA;