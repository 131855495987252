import React, { useEffect, useRef, useState } from 'react';

import SectionTitle from './section-titles';
import Slider from 'react-slick';
import activeItem from '../../images/icons/active-list-item.svg';
import arrowLeft from '../../images/index/left-arrow.svg';
import arrowLeftWhite from '../../images/index/left-arrow-white.svg';
import arrowRight from '../../images/index/right-arrow.svg';
import arrowRightWhite from '../../images/index/right-arrow-white.svg';
import nonActiveItem from '../../images/icons/non-active-list-item.svg';
import styled from 'styled-components';
import testimonialBg from '../../images/index/testimonial-bg.svg';

const TestimonialWrapper = styled.div`
	background-image: url(${(props) => props.testimonialBg});
	background-repeat: no-repeat;
	/* background-size: contain; */
	background-size: 100% 100%;
	background-position: center;
	min-height: 540px;
	width: 100%;
	text-align: center;
`;

const TestimonialTextBox = styled.div`
	margin: 4rem 0.5rem 2rem 0.5rem;
	max-width: 545px;

	@media screen and (max-width: 1200px) {
		background: #218fce;
		border-radius: 30%;
	}
`;
const TestimonialSlider = ({ sectionTitle, opinions, isWhiteArrows, isDark }) => {
	let sliderRef = useRef(null);
	let itemRef = useRef(null);

	const [renderMethod, setRenderMethod] = useState(false);

	useEffect(() => {
		setRenderMethod(true);
	}, []);

	const NextArrow = (props) => {
		return (
			<img
				{...props}
				src={isWhiteArrows ? arrowRightWhite : arrowRight}
				alt="Arrow Right"
				className={props.className + ' next-arrow img-fluid'}
				style={{ ...props.style, display: 'block' }}
				onClick={props.onClick}
			/>
		);
	};

	const PrevArrow = (props) => {
		return (
			<img
				{...props}
				src={isWhiteArrows ? arrowLeftWhite : arrowLeft}
				alt="Arrow Left"
				className={props.className + ' prev-arrow img-fluid'}
				style={{ ...props.style, display: 'block' }}
				onClick={props.onClick}
			/>
		);
	};

	return (
		<>
			<section id="testimonials">
				<div className="row d-flex justify-content-center">
					<div className="col-12">
						<SectionTitle>
							{sectionTitle}
							<hr className={'center ' + (isDark && 'blue')} />
						</SectionTitle>
					</div>

					<div className="col-12 px-md-4 testimonial reasons-slider">
						{renderMethod && (
							<Slider
								{...settings}
								nextArrow={<NextArrow />}
								prevArrow={<PrevArrow />}
								ref={sliderRef}
							>
								{opinions.map(({ node }, index) => (
									<TestimonialWrapper
										key={index}
										testimonialBg={testimonialBg}
										ref={itemRef}
										className="row col-12 d-flex justify-content-center align-items-center"
									>
										<TestimonialTextBox className="col-11 col-sm-7 col-md-5 col-lg-9 col-xl-7 p-5">
											{node.featured_image_url && (
												<div className="d-flex justify-content-center mb-3">
													<img
														src={node.featured_image_url}
														className="testimonial-logo img-fluid"
														alt={node.title}
													/>
												</div>
											)}
											{!node.featured_image_url && (
												<h3>{node.title}</h3>
											)}
											<div
												className="text-testimonial"
												dangerouslySetInnerHTML={{
													__html: node.content
												}}
											/>
											<h5
												className="d-block w-100"
												dangerouslySetInnerHTML={{
													__html: `${
														node.featured_image_url
															? node.title + ', '
															: ''
													}${node.acf.location}`
												}}
											/>
											<BulletPoints
												count={opinions.length}
												active={index}
												sliderRef={sliderRef}
											/>
										</TestimonialTextBox>
									</TestimonialWrapper>
								))}
							</Slider>
						)}
					</div>
				</div>
			</section>
		</>
	);
};

const BulletPoints = ({ count, active, sliderRef }) => {
	let res = [];
	for (var i = 0; i < count; i++) {
		if (i === active) {
			res.push(
				<li className="d-inline-block mx-1">
					<img src={activeItem} alt="активен елемент" />
				</li>
			);
		} else {
			res.push(
				<li className="d-inline-block mx-1">
					<img src={nonActiveItem} alt="неактивен елемент" />
				</li>
			);
		}
	}

	return (
		<div className="d-nones d-inline-block w-100 pr-5 mt-2">
			<ul className="inline-list">
				{res.map((element, index) => (
					<div
						className="d-inline"
						key={index}
						onClick={(e) => {
							sliderRef.current.slickGoTo(index);
						}}
					>
						{element}
					</div>
				))}
			</ul>
		</div>
	);
};

const settings = {
	arrows: true,
	speed: 1000,
	autoplaySpeed: 3000,
	infinite: false,
	autoplay: false,
	focusOnSelect: true,
	pauseOnFocus: true,
	slidesToScroll: 1,
	centerMode: true,
	centerPadding: '750px',
	responsive: [
		{
			breakpoint: 1700,
			settings: {
				centerPadding: '650px'
			}
		},
		{
			breakpoint: 1600,
			settings: {
				centerPadding: '550px'
			}
		},
		{
			breakpoint: 1450,
			settings: {
				centerPadding: '500px'
			}
		},
		{
			breakpoint: 1350,
			settings: {
				centerPadding: '420px'
			}
		},
		{
			breakpoint: 1220,
			settings: {
				centerPadding: '370px'
			}
		},
		{
			breakpoint: 1080,
			settings: {
				centerPadding: '320px'
			}
		},
		{
			breakpoint: 1000,
			settings: {
				centerMode: false,
				arrows: false
				// dots: true,
			}
		}
	]
};

export default TestimonialSlider;

