import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
	${(props) => props.bgImg && `background-image: url(${props.bgImg});`}
	background-size: cover;
	background-repeat: no-repeat;
	border-radius: 8px;
	box-shadow: 0px 8.71336px 58.089px rgba(0, 0, 0, 0.15);
	min-height: 21rem;
`;

const BottomCta = ({ bgImg, children, removeSpacing }) => (
	<>
		<section
			id="bottom-cta"
			className={'center ' + (removeSpacing ? ' ' : 'spacing-top spacing-bottom')}
		>
			<div className="row my-5 justify-content-center align-items-center flex-column w-100">
				<Wrapper
					className="container elevated text-center my-5 py-5"
					bgImg={bgImg}
				>
					<div className="px-md-5 w-100">{children}</div>
				</Wrapper>
			</div>
		</section>
	</>
);

export default BottomCta;
