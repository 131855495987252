import React from 'react';
import Collapsible from 'react-collapsible';
import SectionTitle from './section-titles';

import openIcon from '../../images/icons/collapsible-open.svg';
import closedIcon from '../../images/icons/collapsible-closed.svg';

const CollapsibleSection = ({ sectionTitle, isDark, faqs }) => {

  return (
    <>
      <section id="collapsable-faq" className='center spacing-bottom'>
        <div className='row w-100'>
          <SectionTitle>
            {sectionTitle}
          </SectionTitle>

          <div className='container'>
            <div className='row d-flex justify-content-center'>
              <div className='col-12 col-md-10'>
                {faqs.map(({ title, content }, index) => (
                  <div key={index}>
                    <CollapsibleElement
                      key={index}
                      isInitiallyOpen={index === 0}
                      isDark={isDark}
                      title={title}
                      content={content}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

const CollapsibleElement = ({ isDark, isInitiallyOpen, title, content }) => {

  return (
    <>
      <hr className={'collapsible ' + (isDark && 'dark')} />
      <Collapsible
        open={isInitiallyOpen}
        trigger={
          <CollapsibleHeader
            isOpen={false}
            title={title}
            textClassName={isDark ? 'text-white' : ''}
          />
        }
        triggerWhenOpen={
          <CollapsibleHeader
            isOpen={true}
            title={title}
            textClassName={isDark ? 'text-white' : ''}
          />
        }
      >
        <CollapsibleCard
          content={content}
        />
      </Collapsible>
    </>
  )
}

export const CollapsibleHeader = ({ isOpen, title, textClassName }) => {
  return (
    <>
      <div className='row w-100'>
        <button className='clear w-100' type="button">
          <div className='row w-100'>
            <div className='col-1 p-0 d-flex justify-content-start'>
              <img src={isOpen ? openIcon : closedIcon} className='' alt='Тригър' />
            </div>
            <div className='col-11 pl-md-0 pl-5 w-100 d-flex justify-content-start align-items-center'>
              <h3 className={'text-left ' + textClassName} dangerouslySetInnerHTML={{ __html: title }} />
            </div>
          </div>
        </button>
      </div>
    </>
  )
}

const CollapsibleCard = ({ content }) => {
  return (
    <>
      <div className='row justify-content-end'>
        <div className='col-10 col-md-11 pl-4 pl-md-2' dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </>
  )
}

export default CollapsibleSection;
