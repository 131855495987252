import React, { useEffect, useState } from 'react';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import SectionTitle from './section-titles';
import Slider from "react-slick";
import styled from 'styled-components';

const Card = styled.div`

  /* min-width: 354px; */
  /* max-width: 355px; */

  width: 275px;
  height: 500px;
  margin-top: 1rem;
  margin-left: 5px;
  margin-right: 5px;
  background: white;
  box-shadow: 0px 0px 30px rgba(51, 1, 109, 0.1);
  border-radius: 8px;
  border-bottom: 10px solid ${props => props.color};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  background-color: ${props => props.bg};

  @media screen and (max-width: 1199px) {
    min-width: 300px;
    max-width: 310px;
  }

  @media screen and (max-width: 576px) {
    min-width: 240px;
    max-width: 250px;
    margin-top: 3rem;
    margin-bottom: 2rem;
  }
`

const Reasons = ({ sectionTitle, reasonsData, reasonsWpData, textClassName, isDark }) => {
  const [renderMethod, setRenderMethod] = useState(false);

  let rWpData = [];
  reasonsWpData.split('\n').map(rString => {
    let parts = rString.split('###');

    rWpData.push({
      title: parts[0],
      text: parts[1]
    });
  });


  useEffect(() => {
    setRenderMethod(true);
  }, []);


  const reasonSlides = reasonsData.map((r, index) => (
    <div key={index} className='d-flex justify-content-center'>
      <CardContent
        title={rWpData[index].title}
        content={rWpData[index].text}
        image={r.image}
        color={r.color}
        bgColor={r.bgColor}
        textClassName={textClassName}
      />
    </div>
  ));

  return (
    <>
      <section id='reasons' className='spacing-bottom'>
        <div className='row mb-5'>
          <SectionTitle>
            {sectionTitle}
            <hr className={'center ' + (isDark && 'blue')} />
          </SectionTitle>
          <div className='row w-100 d-none d-xl-flex justify-content-center align-items center'>
            {reasonSlides}
          </div>
          <div className='col-12 px-0 mx-0 px-md-5 d-block d-xl-none reasons-slider'>
            <div className='w-100'>
              {renderMethod && (
                <Slider {...settings}>
                  {reasonSlides}
                </Slider>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

const CardContent = ({ title, image, content, color, bgColor, textClassName }) => {
  const [isOpen, setOpen] = useState(false);
  if (isOpen) {
    return (
      <Card className='px-3 pt-3 text-left card-open' bg={color} color={color} onMouseLeave={(_) => setOpen(false)}>
        <p className={textClassName}>{content}</p>
        <div className='text-center'>
          <span className='icon reason-close'></span>
        </div>
      </Card>
    )
  } else {
    return (
      <Card className='px-2 py-3 text-center' bg={bgColor} color={color} onMouseEnter={(_) => setOpen(true)}>
        <h3 className={textClassName}>{title}</h3>
        <LazyLoadImage
          src={image}
          alt='Причина'
        />
        <div>
          <span className='icon reason-open'></span>
        </div>
      </Card>
    );
  }

}

const settings = {
  speed: 1000,
  arrows: false,
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: '250px',
  focusOnSelect: true,
  pauseOnFocus: true,
  dots: true,
  responsive: [
    {
      breakpoint: 1999,
      settings: {
        centerPadding: '300px',
      }
    },
    {
      breakpoint: 991,
      settings: {
        centerPadding: '200px',
      }
    },
    {
      breakpoint: 860,
      settings: {
        centerPadding: '200px',
      }
    },
    {
      breakpoint: 760,
      settings: {
        centerPadding: '150px',
      }
    },
    {
      breakpoint: 610,
      settings: {
        centerPadding: '130px',
      }
    },
    {
      breakpoint: 500,
      settings: {
        centerPadding: '100px',
      }
    },
    {
      breakpoint: 450,
      settings: {
        centerPadding: '90px',
      }
    },
    {
      breakpoint: 400,
      settings: {
        centerPadding: '70px',
      }
    },
    {
      breakpoint: 360,
      settings: {
        centerPadding: '45px',
      }
    },
  ]
}

export default Reasons;
