import React from 'react'
import SectionTitle from './section-titles'

const VerticalSteps = ({ steps, leftImage, isDark, title }) => {

  return (
    <>
      <section id='business-vertical-steps' className='center spacing-bottom'>
        <div className='row w-100'>
          <div className='container'>
            <SectionTitle className=''>
              {title}
              <hr className={'center ' + (isDark ? 'blue' : '')} />
            </SectionTitle>

            <div className='row d-flex justify-content-center vertical-steps'>
              <div className='col-md-12 col-xl-9'>
                <div className='row'>
                  <div className='col-4 d-none d-md-flex justify-content-end align-items-start'>
                    <img src={leftImage} height='70%' alt='Стъпки за да се качиш на нет-а' />
                  </div>
                  <div className='col-12 col-md-8 p-0 m-0'>
                    {steps.map(({ title, text, text2, img }, index) => (
                      <div key={index + title} className='col-12 text-center text-md-left my-5'>
                        <div className={'w-100 d-flex justify-content-center ' + (index === 1 ? 'mb-2' : 'mb-4') }>
                          <img src={img} className='img-fluid d-block d-md-none align-center' alt={'Стъпка към включване ' + (index + 1)} />
                        </div>
                        <h6 className={isDark ? 'text-white' : ''}>{title}</h6>
                        <p className={isDark ? 'text-white' : ''}>{text}</p>
                        <p className={isDark ? 'text-white' : ''}>{text2}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default VerticalSteps;